export const classSchema = {
    "classes": {
        "form": "dar-form",
        "select": "form-control",
        "typeaheadCustom": "form-control",
        "datePickerCustom": "form-control",
        "question": "form-group",
        "input": "form-control",
        "button": "btn btn-primary",
        "radioListItem": "dar__radio--item",
        "radioList": "dar__radio--list list-group",
        "checkboxInput": "checkbox list-group",
        "checkboxListItem": "dar__check--item ",
        "checkboxList": "dar__check list-group",
        "controlButton": "btn btn-primary pull-right",
        "backButton": "btn btn-default pull-left",
        "errorMessage": "alert alert-danger",
        "buttonBar": "button-bar hidden" 
    }
}